@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.login_background_image {
  background-image: url("./assets/images/login-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  padding-top: 40px;
  background-color: rgb(0, 0, 0);
 

}

.font-family{
  font-family: 'Inter', sans-serif;
}

textarea {
  resize: none !important;
}

div{
  font-family: 'Inter', sans-serif; 
}
.singUp-link.css-101ca9i-MuiTypography-root-MuiLink-root {
  color: white;
  text-decoration: none;
}

.authentication-btn.css-1kftjmi-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  margin-top: 15px;
}

.custom-date.css-1jr6r0j-MuiStack-root-MuiMultiInputDateRangeField-root {
  /* background-color: #e5f0ff !important; */
}

.custom-date .MuiBox-root.css-70qvj9 input {
  background-color: #e5f0ff !important;
  display: flex !important;
  align-items: center !important;
  width: 105px !important;
  padding: 0 !important;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

body {
  font-family: 'Inter', sans-serif;
}

p {
  font-family: 'Inter', sans-serif;
}

.login-input .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.4375em !important;
}

.login-input .MuiFormControl-root .css-r47a1p-MuiFormControl-root {
  height: 0.4375em !important;
  width: 100% !important;
}

.organizer_input {
  text-decoration: none;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  border-bottom: none !important;
}

.app-bar .css-139aifj-MuiPaper-root-MuiAppBar-root {
  margin: 0 5px;
}

.css-jh47zj-MuiButtonBase-root-MuiButton-root {
  width: 100%;
  background-color: green;
}

input:focus,
select:focus {
  border-color: #4299e1;
}

input.input-error,
select.input-error {
  border-color: #fc8181;
}

.eventCategory-select .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  /* background-color: #f1f1f1 !important; */
  border: 1px solid #C7C9D9 !important;
  padding: 10px 0 0 10px;
  height: 36px;
  border-radius: 10px;
}

.details {
  display: grid;
  grid-template-columns: 300px 300px;
  grid-template-rows: 180px 150px 200px 150px;
}

@media only screen and (max-width: 600px) {
  .details {
    display: flex;
  }
}

.css-yk16xz-control {
  height: 52px !important;
  border: 1px solid grey;
  border-radius: 7px !important;
  height: 47px;
}

.overflow {
  overflow: scroll;
}

.parent {
  overflow: scroll;
}

.parent::-webkit-scrollbar {
  display: none;
}

.css-kc0d0a-MuiFormControl-root,
.css-roy203-MuiFormControl-root {
  margin: 0 !important;
}

/* input {
  height: 40px !important;
} */

.css-1g6gooi {
  margin-left: 0 !important;
  padding: 0 !important;
}

.css-1okebmr-indicatorSeparator {
  width: 0 !important;
}

.active {
  display: block;
}

.inActive {
  display: none;
}

.gridStyle {
  display: grid;
  grid-template-columns: 300px 300px;
  grid-template-rows: 180px  110px 200px 150px;
}

.gridStyleChild1 {

  grid-area: 1/1 / span 2 / span 5;
}

.gridStyleChild2 {
  grid-area: 2/1 / span 4 / span 5;
  z-index: 23;

}

.gridDetails {
  display: grid;
  grid-template-columns: 300px 300px;
  grid-template-rows: 220px 110px 180px 100px 100px;;
}

.gridDetails1 {
  grid-area: 1/1 / span 1 / span 6;
}

.gridDetails2 {
  grid-area: 2/1 / span 4 / span 6;
  place-items: center;
  justify-content: center;
  display: flex;
  overflow: scroll;
  /* background: #000; */
}

.gridDetails2::-webkit-scrollbar {
  display: none;
}

.fontStyle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.Background {
  background-image: url("./assets/images/musician-plays right.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  
  /* rotate: 1050deg; */
}

.BackgroundDetails {
  background-image: url("./assets/images/Rectangle 19.png");
  background-size: cover;
  background-repeat: no-repeat;
  
  /* rotate: 1050deg; */
}
.Child_Div {
  /* border-bottom-left-radius: 60% 15% ;
  border-bottom-right-radius: 60% 19%; */
  border-radius: 0% 100% 100% 100% / 60% 0% 19% 15%;
}

.text {
  text-align: center;
  transition: all 0.8s ease;
}

.content {
  max-height: 0;
  overflow: hidden;
  color: #667085;
  transition: all 0.3s cubic-bezier(0,1);
 
}

.contentShow {
  margin-bottom: 20px;
  color: #667085;
  height: auto;
  max-height: 9999px;
  transition: all 0.5s ease;
font-family: 'Inter', sans-serif;;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  max-width: 20ch;
}

/* .p-tag{
  visibility: visible;
  background: red;
}
.bar{
  background: #000;
}
.bar:hover {
  display: block;
  background: red;

}
.bar:hover ~ .p-tag{
 background: #000;
  background: red;

} */