@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");
body {
  background-color: #e7ece5;
  font-family: "Inter", sans-serif !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* background: rgb(0 100 0/0.1) !important; */
}
